<template>
  <div class="supplydemand">
    <div class="supplydemand-left">
      <div class="supplydemand-left-header">
        <div class="info_header_left">我收藏的产品</div>
        <div class="message-totle">共 {{ total }} 条</div>
      </div>

      <div class="user-list-box">
        <div v-if="total == 0" class="empty-box">
          <div class="img-empty-wrap">
            <!-- <i class="el-icon-document"></i> -->
          </div>
          <div class="content-slot-wrap">这里什么都没有</div>
        </div>
        <div
          v-else
          v-for="item in exhibitList"
          @click="goProductsDetails(item)"
        >
          <div class="user-list-items">
            <div class="header-img">
              <img :src="item.exhibits.cover" />
            </div>
            <div class="message">
              <div>
                {{ item.exhibits.name }}
                <span>{{ item.exhibits.createMan }}</span>
              </div>
              <!-- <div>
                <i class="iconfont icon-gongsichaxun"></i>
                所在公司：{{ item.company }}
              </div> -->
            </div>
            <div class="textarea-date-box">
              <div class="Operationtype">
               
                <div @click.stop="clickProductsFav(item.conferenceCompanyExhibitsId)">
                 <span class="compant_shoucang" >
                <img :src="imgIcon.imgCangTrue" />
                <span>已收藏</span>
              </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="currentPage"
        :page-size="10"
        layout="prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { exhibitsFavList,exhibitsFavDown, } from "@/api";
import { imgObj } from "@/utils/const.js";
export default {
  name: "supplydemand",

  data() {
    return {
      queryParams: {
        //列表搜索参数
        pageIndex: 1,
        pageSize: 10,
        conferenceId:7,
        userId: localStorage.getItem("userId") - 0,
      },
      exhibitList: [],
      classifylist: [],
      resultlist: [],
      currentPage: 1,
      peopletype: "最新加入",
      Supplylist: [],
      total: 0,
      hotsupplylist: [],
      dialogVisible: false,
      imgIcon: imgObj,
    };
  },
  methods: {
    getProductsList() {
      exhibitsFavList(this.queryParams).then((res) => {
        console.log("展品", res);
        this.exhibitList = res.data.data.item1;
        this.total = res.data.data.item2;
      });
    },
    handleClose(done) {
      this.dialogVisible = false;
    },
    getFav() {
      fav().then((res) => {
        console.log(res);
        this.Supplylist = res.data;
        // this.total = this.Supplylist.length;
      });
    },
     clickProductsFav(exhibitId) {
      let username = window.localStorage.getItem("userName");
      console.log(username);
      if (username) {
        let data = {
          userId: localStorage.getItem("userId") - 0,
          conferenceCompanyExhibitsId: exhibitId,
        };

        exhibitsFavDown(data).then((res) => {
          this.getProductsList();
        });
      } else {
        // this.$router.push('/login')
        this.$message({
          message: "请先登陆才能点赞",
          type: "warning",
        });
      }
    },
    handleSizeChange(e) {
      console.log(e);
    },
    handleCurrentChange(e) {
      console.log(e);
    },
    changefirstbtn(item, index) {
      this.classifytext = item.title;
      this.resultlist = this.classifylist[index].children;
      this.resulttext = "全部";
      this.changelist(item.id);
    },
    changesecondbtn(item) {
      this.resulttext = item.title;
      this.changelist(item.id);
    },
    changelist(id) {
      getSupplyclassifyid(id).then((res) => {
        this.Supplylist = res.data;
        this.total = this.Supplylist.length;
      });
    },
    goProductsDetails(item) {
      console.log(item);
      let routeData = this.$router.resolve({
        name: "productDetails",
        query: {
          id: item.exhibitId,
          conferenceCompanyId: item.conferenceCompanyId,
        },
      });
      window.open(routeData.href);
    },
  },
  mounted() {
    this.getProductsList();
  },
};
</script>

<style lang="less" scoped>
.supplydemand {
  //   width: 1280px;
  padding: 0.625rem 0.9375rem;
  display: flex;
  justify-content: space-between;
  .supplydemand-left {
    width: 100%;
    margin-right: 20px;
    .supplydemand-left-header {
      height: 48px;
      line-height: 48px;
      font-size: 18px;
      font-weight: 600;
      color: #2e2e2e;
      padding-left: 10px;
      display: flex;
      justify-content: space-between;
    }
    .message-totle {
      // padding: 20px;
      font-size: 14px;
      color: #989898;
      text-align: right;
    }
    .user-list-box {
      padding: 20px;
      background: white;
      .empty-box {
        padding: 4rem 0;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        .img-empty-wrap {
          display: block;
          width: 180px;
          height: 180px;
          background-size: cover;
          background-position: 50%;
          background-repeat: no-repeat;
          // font-size: 65px;
          color: #8a919f;
          background-image: url("https://mcnki.obs.cn-east-2.myhuaweicloud.com/img/empty.png");
        }
        .content-slot-wrap {
          font-size: 14px;
          font-weight: 400;
          color: #8a919f;
        }
      }
      .user-list-items {
        padding: 20px 0;
        display: flex;
        align-items: center;
        cursor: pointer;
        .header-img {
          width: 100px;
          margin-right: 50px;
          margin-left: 13px;
          img {
            width: 80px;
            height: 80px;
            border-radius: 50%;
          }
        }
        .message {
          width: 100%;
          div:nth-child(1) {
            font-size: 20px;
            font-weight: bold;
            span {
              padding: 5px 16px;
              font-size: 14px;
              color: #6a6a6a;
              margin-left: 20px;
              background: #f5f6f6;
            }
          }
          div:nth-child(2) {
            margin-top: 15px;
            font-size: 14px;
            color: #9a999e;
          }
        }
        .textarea-date-box {
          display: flex;
          justify-content: space-between;
          margin-top: 10px;
          span:nth-child(2) {
            font-size: 14px;
            color: #999;
          }
          .Operationtype {
            display: flex;
            font-size: 14px;
            color: #999;
            cursor: pointer;
             .compant_shoucang {
            display: flex;
    align-items: center;
            border: 0.0625rem solid #ededed;
            padding: 0.6rem 1.2rem;
            background: #fff;
            color: #525252;
            font-size: 0.875rem;
            font-weight: 400;
            margin: auto;
          }
            div {
            // padding: 10px 25px;
            // background: #fff;
            color: #525252;
            font-size: 14px;
            margin-right: 20px;
            // border: 1px solid #ededed;
            display: flex;
            align-items: flex-end;
            span {
              margin-left: 10px;
              min-width: 44px;
            }
          }
            // div:nth-child(1) {
            //   padding: 10px 20px;
            //   background: #f5f6f6;
            //   color: #0966a9;
            //   font-size: 14px;
            // }
            // div {
            //   padding: 10px;
            //   margin-right: 20px;
            // }
            .count {
              margin-left: 10px;
            }
          }
        }
        .friends {
          width: 100px;
          color: white;
          background: #0966a9;
          font-size: 16px;
          padding: 10px 31px;
          border-radius: 29px;
          text-align: center;
          cursor: pointer;
          .iconfont {
            margin-right: 10px;
          }
        }
      }
      .user-list-items:hover{
        background: #e9e9e973;
      }
    }
  }

  .el-pagination {
    margin-top: 50px;
    display: flex;
    justify-content: center;
    /deep/ li {
      margin: 0 8px !important;
      min-width: 36px !important;
      height: 34px;
      line-height: 36px;
    }
    /deep/ .active {
      color: white;
      background: #0966a9;
    }
    /deep/ li:hover {
      color: white;
      background: #0966a9;
    }
    /deep/ button {
      margin: 0 8px !important;
      height: 34px;
      line-height: 36px;
    }
    /deep/ .btn-next {
      padding: 0;
    }
    /deep/ .btn-prev {
      padding: 0;
    }
    /deep/ button:hover {
      color: white;
      background: #0966a9;
    }
    /deep/ span {
      margin-right: 30px;
      line-height: 36px;
    }
  }
}
</style>